body {
  margin: 0;
}

.backrest.operation-details, .backrest.file-details {
  color: gray;
  font-family: monospace;
  font-size: .7em;
}

pre {
  white-space: pre-wrap;
  overflow-x: auto;
}

.visible-on-hover .hidden-child {
  visibility: hidden;
}

.visible-on-hover:hover .hidden-child {
  visibility: visible;
}

.float-center-right {
  right: 0;
  top: "50%";
  transform: "translateY(-50%)";
  position: absolute;
}

.react-js-cron {
  flex-wrap: wrap;
  align-items: flex-start;
  display: flex;
}

.react-js-cron > div, .react-js-cron-field {
  align-items: center;
  display: flex;
}

.react-js-cron-field {
  margin-bottom: 10px;
}

.react-js-cron-field > span, div.react-js-cron-select {
  margin-left: 5px;
}

.react-js-cron-select.react-js-cron-select-no-prefix {
  margin-left: 0;
}

div.react-js-cron-error .react-js-cron-select .ant-select-selector {
  background: #fff6f6;
  border-color: #ff4d4f;
}

div.react-js-cron-custom-select {
  z-index: 1;
  min-width: 70px;
}

div.react-js-cron-error div.react-js-cron-custom-select {
  background: #fff6f6;
}

div.react-js-cron-select.react-js-cron-custom-select.ant-select div.ant-select-selector {
  padding-left: 11px;
  padding-right: 30px;
}

.react-js-cron-read-only div.react-js-cron-select.react-js-cron-custom-select.ant-select div.ant-select-selector {
  padding-right: 11px;
}

div.react-js-cron-custom-select .ant-select-selection-search {
  width: 0 !important;
  margin: 0 !important;
}

div.react-js-cron-custom-select .ant-select-selection-placeholder {
  opacity: 1;
  color: inherit;
  transition: none;
  position: static;
  top: 50%;
  left: auto;
  right: auto;
  transform: none;
}

.react-js-cron-week-days-placeholder .react-js-cron-custom-select .ant-select-selection-placeholder, .react-js-cron-month-days-placeholder .react-js-cron-custom-select .ant-select-selection-placeholder {
  opacity: .4;
}

.react-js-cron-custom-select-dropdown {
  width: 174px !important;
  min-width: 0 !important;
}

.react-js-cron-custom-select-dropdown .rc-virtual-list {
  max-height: none !important;
}

.react-js-cron-custom-select-dropdown-grid .rc-virtual-list-holder {
  max-height: initial !important;
}

.react-js-cron-custom-select-dropdown-grid .rc-virtual-list-holder-inner {
  grid-template-columns: repeat(4, 1fr);
  display: grid !important;
}

.react-js-cron-custom-select-dropdown-grid .rc-virtual-list-holder-inner .ant-select-item-option-content {
  text-align: center;
}

.react-js-cron-custom-select-dropdown-hours-twelve-hour-clock {
  width: 260px !important;
}

.react-js-cron-custom-select-dropdown-minutes-large {
  width: 300px !important;
}

.react-js-cron-custom-select-dropdown-minutes-large .rc-virtual-list-holder-inner {
  grid-template-columns: repeat(6, 1fr);
}

.react-js-cron-custom-select-dropdown-minutes-medium {
  width: 220px !important;
}

.react-js-cron-custom-select-dropdown-minutes-medium .rc-virtual-list-holder-inner {
  grid-template-columns: repeat(5, 1fr);
}

.react-js-cron-period > span:first-child {
  margin-left: 0 !important;
}

.react-js-cron-period .react-js-cron-select.ant-select-single.ant-select-open .ant-select-selection-item {
  opacity: 1;
}

.react-js-cron-select-dropdown-period {
  width: auto !important;
  min-width: 0 !important;
}

.react-js-cron-clear-button {
  margin-bottom: 10px;
  margin-left: 10px;
}

.react-js-cron-disabled .react-js-cron-select.ant-select-disabled {
  background: #f5f5f5;
}

div.react-js-cron-select.react-js-cron-custom-select.ant-select div.ant-select-selector > .ant-select-selection-overflow {
  flex: initial;
  align-items: center;
}

/*# sourceMappingURL=index.7c36ca54.css.map */
